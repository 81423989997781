var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{class:!_vm.isMobile &&
        !_vm.hideLayout &&
        !_vm.$route.name.includes('Auth') &&
        _vm.toggleLayout
          ? 'content '
          : 'white'},[(!_vm.$route.name.includes('Auth') && !_vm.hideLayout && _vm.toggleLayout)?_c('div',[(!_vm.isMobile)?_c('div',{staticClass:"navbar-max-width py-4"},[_c('div',{staticClass:"max-width d-flex align-center justify-space-between"},[_c('span',{staticClass:"black--text font-24"}),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center py-3 radius-10"},[(_vm.accountStatus === 'trialing' && _vm.profile.trial)?_c('div',{},[(!_vm.isTrialWillExpire)?_c('span',{staticClass:"trial-countdown d-inline-block"},[_c('span',{staticClass:"font-600"},[_vm._v("Free Trial")]),_vm._v(" ("+_vm._s(_vm.$moment(_vm.profile.trial.date) .tz(this.profile.timeZone) .fromNow(true) + " remaining")+") ")]):_c('v-btn',{staticClass:"confirm-payment d-inline-block",attrs:{"color":"danger","loading":_vm.processing},on:{"click":function($event){return _vm.redirectToPayment()}}},[_vm._v(" Please confirm the payment to continue ("+_vm._s(_vm.$moment(_vm.profile.trial.date) .tz(this.profile.timeZone) .fromNow(true) + " remaining")+") ")])],1):_vm._e(),(
                    _vm.profile.role !== 'admin' &&
                    _vm.accountStatus === 'active' &&
                    _vm.profile.plan &&
                    _vm.profile.plan.isFree &&
                    !_vm.profile.hadTrial
                  )?_c('div',{staticClass:"d-inline"},[_c('v-btn',{staticClass:"trial-countdown",on:{"click":function($event){return _vm.redirectToUpgrade()}}},[_vm._v("Try Ofero Pro: "),_c('span',{staticClass:"font-600"},[_vm._v(" 14-Day Free Trial!")])])],1):(
                    _vm.profile.role !== 'admin' &&
                    _vm.accountStatus === 'active' &&
                    _vm.profile.plan &&
                    _vm.profile.plan.isFree &&
                    _vm.profile.hadTrial
                  )?_c('div',{staticClass:"d-inline"},[_c('v-btn',{staticClass:"trial-countdown font-600",on:{"click":function($event){return _vm.redirectToUpgrade()}}},[_vm._v("Upgrade to Pro")])],1):_vm._e(),(_vm.profile.currency)?_c('span',{staticClass:"font-18 font-600 user-plan mx-3 cursor-pointer",on:{"click":function($event){_vm.showCurrencyDialog = true}}},[_vm._v(" "+_vm._s(_vm.profile.currency.symbol)+" ")]):_vm._e(),(_vm.profile.plan)?_c('span',{staticClass:"font-18 font-600 user-plan"},[_vm._v(_vm._s(_vm.profile.plan.title))]):_vm._e()])])])]):_vm._e(),(_vm.isMobile)?_c('mobile-layout'):_vm._e(),(!_vm.isMobile && !_vm.$route.name.includes('Auth'))?_c('side-menu'):_vm._e()],1):_vm._e(),_c('router-view'),_c('v-dialog',{attrs:{"max-width":"400","content-class":" radius-25"},model:{value:(_vm.showCurrencyDialog),callback:function ($$v) {_vm.showCurrencyDialog=$$v},expression:"showCurrencyDialog"}},[_c('currency-dialog',{attrs:{"item":_vm.profile},on:{"close":function($event){_vm.showCurrencyDialog = false}}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
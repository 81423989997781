<template>
  <v-menu offset-y left max-height="400" min-width="300px" content-class=" custom-menu radius-15 darkgrey">
    <!-- icone inside Free plan -->
    <!-- <template v-slot:activator="{ on, attrs }">
      <img v-bind="attrs" v-on="on" width="32px" height="32px" class="cursor-pointer"
        :src="require('@/assets/images/icons/user.svg')" alt="" />
    </template> -->
    <div class="profile-popup">
      <div class="profile-popup__head primary pa-4">
        <span class="font-18 white--text d-block">{{ userInfo.name }}</span>
        <span class="font-14 white--text d-block">{{ userInfo.email }}</span>
      </div>
      <div class="profile-popup__content darkgrey px-4 py-8">
        <div class="d-flex align-start mb-2" v-for="(item, i) in items" :key="i">
          <img class="mr-3 mt-1" :src="item.icon" alt="" />
          <span class="font-20 d-block pb-2 w-100">{{ item.title }}</span>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    items: [
      {
        title: "Subscription",
        icon: require("@/assets/images/icons/subscribe.svg"),
      },
      {
        title: "Reset Password",
        icon: require("@/assets/images/icons/lock.svg"),
      },
      { title: "Support", icon: require("@/assets/images/icons/support.svg") },
    ],
  }),
};
</script>

<style lang="scss">
.profile-popup__content {
  span {
    border-bottom: 1px solid #c3c3c3;
  }
}
</style>

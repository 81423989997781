<template>
  <div class="side-menu bg-grey py-8 d-flex flex-column">
    <div class="d-flex flex-column overflow-y-auto">
      <div class="flex-grow-1 menu-links">
        <div
          class="side-menu__head text-center primary mx-7"
          :class="[
            profile.logo || imgDataUrl ? 'align-start pt-3' : 'align-center mb-7',
          ]"
        >
          <img
            :class="[profile.logo || imgDataUrl ? 'mt-2' : '']"
            :src="require('@/assets/images/global/logo.svg')"
            alt=""
          />
          <!-- <p class="beta mt-1">Beta</p> -->
        </div>

        <div v-if="profile.logo || imgDataUrl" class="side-menu__profile">
          <div
            class="profile-img"
            :class="[!profile.logo && !imgDataUrl ? 'profile-img__empty' : '']"
          >
            <img
              v-if="profile.logo || imgDataUrl"
              width="100%"
              height="100%"
              :src="profile.logo ? profile.logo.url : imgDataUrl"
            />

            <!-- <img
              v-else
              :src="require('@/assets/images/icons/img-placeholder.svg')"
              alt="image placeholder"
              class="img-placeholder"
            /> -->
          </div>
          <div class="side-menu__profile__edit primary" @click="show = true">
            <img
              class="cursor-pointer"
              :src="require('@/assets/images/icons/pencil.svg')"
              alt=""
            />
          </div>
        </div>

        <!-- top icons -->
        <div
          class="cursor-pointer side-tab pl-10 mr-8"
          :class="link.active ? 'side-tab--active' : ''"
          v-for="(link, i) in topLinks"
          :key="'top' + i"
          @click="goToPage(link)"
          tabindex="-1"
          @focus="toggleProTip"
          @blur="toggleProTip"
        >
          <div class="mb-2 py-1 position-relative">
            <span class="font-22 d-flex align-center"
              >{{ link.title }}

              <span>
                <img
                  v-if="
                    i == 2 &&
                    link.title === 'Offers Page' &&
                    profile.plan &&
                    profile.plan.isFree
                  "
                  class="pro-flag ml-2"
                  width="20px"
                  height="20px"
                  :src="require('@/assets/images/icons/pro-flag.svg')"
                />
              </span>
            </span>
          </div>
        </div>
        <!-- bottom icons -->
        <div class="bottom-links mx-10 my-3"></div>
        <div
          v-for="(link, i) in bottomLinks"
          :key="'bottom' + i"
          class="side-tab px-10 mr-8"
          :class="link.active ? 'side-tab--active' : ''"
          @click="goToPageBottom(link)"
        >
          <div class="mt-2 py-1 cursor-pointer">
            <span class="font-22">{{ link.title }}</span>
          </div>
        </div>
        <div
          class="mt-1 cursor-pointer px-11"
          @click="logoutHandler"
          v-if="token"
        >
          <span class="lightred--text font-22">Logout</span>
        </div>
      </div>
      <div class="plan-details ms-10 mt-10" v-if="profile.planDetails">
        <span class="d-block font-16 font-600">Plan Details</span>
        <div class="plan-details__cover">
          <span class="d-block font-500 font-16"
            >Items Count:
            <span class="font-600">{{
              profile.planDetails ? profile.planDetails.productsAllowed : ""
            }}</span></span
          >
          <span class="d-block font-500 font-16">
            Items Used:
            <span class="font-600">{{
              profile.planDetails ? profile.planDetails.productsUsed : "0"
            }}</span></span
          >

          <!-- <span class="d-block font-500 font-18">Template Count: <span class="font-600">100</span></span>
        <span class="d-block font-500 font-18">Used: <span class="font-600">25</span></span> -->
        </div>

        <span
          v-if="
            !(profile.plan && profile.plan.isFree) &&
            profile.planDetails.endDate
          "
          class="d-block font-12 mt-1"
          >Expire Date
          <span class="font-600">
            {{
              $moment(profile.planDetails.endDate)
                .tz(this.profile.timeZone)
                .format("MMM DD, YYYY")
            }}</span
          ></span
        >

        <span v-else class="d-block font-18 mt-1"> Free lifetime </span>
      </div>
    </div>

    <v-dialog
      v-model="show"
      content-class="no-shadow white-bg radius-15 relative upload-dialog"
    >
      <my-upload
        field="img"
        @crop-success="cropSuccess"
        :width="300"
        :height="300"
        img-format="png"
        langType="en"
        :noSquare="true"
      ></my-upload>
      <img
        width="15px"
        height="15px"
        :src="require('@/assets/images/icons/close.svg')"
        class="upload-cancel cursor-pointer"
        @click="show = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import myUpload from "vue-image-crop-upload";
export default {
  components: {
    "my-upload": myUpload,
  },
  data: () => ({
    topUserLinks: [
      {
        route: "/",
        title: "Media Panel",
        active: true,
        visible: true,
      },
      {
        route: "/screens",
        title: "Screen",
        active: false,
        visible: true,
      },
      {
        route: "/offers-page",
        title: "Offers Page",
        active: false,
        visible: false,
      },
    ],
    topAdminLinks: [
      {
        route: "/admin/packages",
        title: "Packages",
        active: false,
      },
      {
        route: "/admin/plans",
        title: "Plans",
        active: false,
      },
      {
        route: "/admin/users",
        title: "Users",
        active: false,
      },
    ],
    bottomLinks: [
      {
        title: "My Plan",
        route: "/my-plan",
        active: false,
      },
      {
        title: "Settings",
        route: "/settings",
        active: false,
      },
    ],

    imgDataUrl: "",
    show: false,
    proTipDialog: false,
  }),
  computed: {
    ...mapGetters(["token"]),
    topLinks() {
      return this.role == "user" ? this.topUserLinks : this.topAdminLinks;
    },
  },
  watch: {
    $route: {
      handler() {
        this.checkRoute();
      },
    },
    profile: {
      handler() {
        if (this.role == "user") {
          const offersPageLink = this.topUserLinks.find(
            (i) => i.title === "Offers Page"
          );
          offersPageLink.visible = this.profile.plan?.isOfferPage;
        }
      },
    },
  },
  methods: {
    toggleProTip() {
      console.log("focus");
      this.showProTip = !this.showProTip;
    },
    async cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;

      this.uploadLogo(imgDataUrl).then((res) => {
        this.show = false;
      });
    },
    goToPage(link) {
      if (
        link.title === "Offers Page" &&
        this.profile.plan &&
        this.profile.plan.isFree
      ) {
        this.$store.dispatch("setProTipDialog", {
          show: true,
          title: "Offers page",
          description:
            "Engage Customers Online with Our Digital Offers Page. Showcase your deals effectively in a vibrant, online format.",
        });
        return;
      }

      const activeRoute = this.topLinks.find((i) => i.active === true);
      this.topLinks.forEach((i) => {
        if (link.title == i.title) {
          i.active = true;
        } else {
          i.active = false;
        }
      });

      if (activeRoute && link.route === activeRoute.route) {
        return;
      }

      this.$router.push(link.route);
    },
    goToPageBottom(link) {
      this.topLinks.forEach((i) => (i.active = false));
      this.bottomLinks.forEach((i) => {
        if (link.route == i.route) {
          i.active = true;
        } else {
          i.active = false;
        }
      });
      this.$router.push(link.route);
    },
    checkRoute() {
      this.topLinks.forEach((i) => (i.active = false));
      this.bottomLinks.forEach((i) => (i.active = false));

      let activeRoute = this.topLinks.filter(
        (i) => i.route == this.$route.fullPath
      );
      if (activeRoute.length > 0) {
        this.topLinks.filter(
          (i) => i.route == this.$route.fullPath
        )[0].active = true;
        return;
      }
      this.bottomLinks.filter(
        (i) => i.route == this.$route.fullPath
      )[0].active = true;
    },
    logoutHandler() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
  },
  async mounted() {
    this.checkRoute();

    if (this.profile.role === "admin") {
      this.bottomLinks = this.bottomLinks.filter(
        (link) => link.route !== "/my-plan"
      );
    }
  },
};
</script>

<style lang="scss">
.beta {
  color: #ffff;
}

.side-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 260px;
  z-index: 99;
  border-right: 1px solid #acacac;
  // overflow-y: auto;
  // overflow-x: visible;

  .menu-links {
    min-height: 500px;
  }

  &__head {
    height: 90px;
    border-radius: 25px;
    display: flex;
    // align-items: start;
    justify-content: center;
    // padding-top: 10px;
  }

  &__profile {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    margin: auto;
    position: relative;
    top: -30px;
    .profile-img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 50%;

      &__empty {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__edit {
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
    }

    &:hover & {
      &__edit {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .bottom-links {
    border-top: 1px solid #acacac;
  }

  .active-icon {
    filter: invert(0%) sepia(6%) saturate(7476%) hue-rotate(287deg)
      brightness(97%) contrast(106%);
  }

  .side-tab {
    position: relative;

    // &::before {
    //   content: "";
    //   left: 0px;
    //   width: 5px;
    //   height: 100%;
    //   position: absolute;
    //   opacity: 0;
    //   transition: 0.3s;
    //   background: $lightgreen;
    //   border-radius: 0px 15px 15px 0px;
    // }

    &--active {
      border-radius: 0px 15px 15px 0px;
      background: #0195a9;
      color: #ffffff;

      // &::before {
      //   opacity: 1;
      // }
    }
  }

  .plan-details {
    width: 200px;
    // position: absolute;
    // bottom: 50px;
    &__cover {
      background: #f9f9f9;
      border: 1px solid #eaeaea;
      border-radius: 15px;
      padding: 15px;
      width: 100%;
    }
  }

  .pro-tip {
    width: 230px;
    position: absolute;
    right: -30px;
    bottom: -100%;
    transform: translateY(50%);
    background: #f4f4f4;
    color: #000;
    padding: 12px;
    border-radius: 15px;
    border: 1px solid #d9d9d9;
    z-index: 99;

    .upgrade-btn {
      border-radius: 15px;
      background: #fdd039;
      padding: 10px 15px;
    }
  }
}
</style>

<template>
  <div class="side-menu bg-grey py-8 d-flex flex-column">
    <div class="flex-grow-1">
      <div
        class="side-menu__head text-center primary mx-7"
        :class="[
          profile.logo || imgDataUrl ? 'align-start pt-3' : 'align-center mb-7',
        ]"
      >
        <img
          :class="[profile.logo || imgDataUrl ? 'mt-2' : '']"
          :src="require('@/assets/images/global/logo.svg')"
          alt=""
        />
        <!-- <p class="beta mt-1">Beta</p> -->
      </div>

      <div v-if="profile.logo || imgDataUrl" class="side-menu__profile">
        <div
          class="profile-img"
          :class="[!profile.logo && !imgDataUrl ? 'profile-img__empty' : '']"
        >
          <img
            v-if="profile.logo || imgDataUrl"
            width="100%"
            height="100%"
            :src="profile.logo ? profile.logo.url : imgDataUrl"
          />

          <!-- <img
              v-else
              :src="require('@/assets/images/icons/img-placeholder.svg')"
              alt="image placeholder"
              class="img-placeholder"
            /> -->
        </div>
        <div class="side-menu__profile__edit primary" @click="show = true">
          <img
            class="cursor-pointer"
            :src="require('@/assets/images/icons/pencil.svg')"
            alt=""
          />
        </div>
      </div>

      <!-- top icons -->
      <div
        class="cursor-pointer side-tab px-10 mr-8"
        :class="link.active ? 'side-tab--active' : ''"
        v-for="(link, i) in topLinks"
        :key="'top' + i"
        @click="goToPage(link)"
      >
        <div class="mb-2 py-1">
          <span class="font-22">{{ link.title }}</span>
        </div>
      </div>
      <!-- bottom icons -->
      <div class="bottom-links mx-10 my-3"></div>
      <div
        v-for="(link, i) in bottomLinks"
        :key="'bottom' + i"
        class="side-tab px-10 mr-8"
        :class="link.active ? 'side-tab--active' : ''"
        @click="goToPageBottom(link)"
      >
        <div class="mt-2 py-1 cursor-pointer">
          <span class="font-22">{{ link.title }}</span>
        </div>
      </div>
      <div
        class="mt-1 cursor-pointer px-11"
        @click="logoutHandler"
        v-if="token"
      >
        <span class="lightred--text font-22">Logout</span>
      </div>
    </div>
    <div class="plan-details mx-10 mt-10" v-if="profile.planDetails">
      <span class="d-block font-16 font-600">Plan Details</span>
      <div class="plan-details__cover">
        <span class="d-block font-500 font-16"
          >Items Count:
          <span class="font-600">{{
            profile.planDetails ? profile.planDetails.productsAllowed : ""
          }}</span></span
        >
        <span class="d-block font-500 font-16">
          Items Used:
          <span class="font-600">{{
            profile.planDetails ? profile.planDetails.productsUsed : "0"
          }}</span></span
        >

        <!-- <span class="d-block font-500 font-18">Template Count: <span class="font-600">100</span></span>
        <span class="d-block font-500 font-18">Used: <span class="font-600">25</span></span> -->
      </div>

      <span
        v-if="!profile.plan.isFree && profile.planDetails.endDate"
        class="d-block font-12 mt-1"
        >Expire Date
        <span class="font-600">
          {{
            $moment(profile.planDetails.endDate).format("MMM DD, YYYY")
          }}</span
        ></span
      >

      <span v-else class="d-block font-18 mt-1"> Free lifetime </span>
    </div>

    <v-dialog
      v-model="show"
      content-class="no-shadow white-bg radius-15 relative upload-dialog"
    >
      <my-upload
        field="img"
        @crop-success="cropSuccess"
        :width="300"
        :height="300"
        img-format="png"
        langType="en"
        :noSquare="true"
      ></my-upload>
      <img
        width="15px"
        height="15px"
        :src="require('@/assets/images/icons/close.svg')"
        class="upload-cancel cursor-pointer"
        @click="show = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import myUpload from "vue-image-crop-upload";
export default {
  components: {
    "my-upload": myUpload,
  },
  data: () => ({
    topUserLinks: [
      {
        route: "/",
        title: "Media Panel",
        active: true,
        visible: true,
      },
      {
        route: "/screens",
        title: "Screens",
        active: false,
        visible: true,
      },
      {
        route: "/offers-page",
        title: "Offers Page",
        active: false,
        visible: false,
      },
    ],
    topAdminLinks: [
      {
        route: "/admin/packages",
        title: "Packages",
        active: false,
      },
      {
        route: "/admin/plans",
        title: "Plans",
        active: false,
      },
      {
        route: "/admin/users",
        title: "Users",
        active: false,
      },
    ],
    bottomLinks: [
      {
        title: "My Plans",
        route: "/my-plan",
        active: false,
      },
      {
        title: "Settings",
        route: "/settings",
        active: false,
      },
    ],

    imgDataUrl: "",
    show: false,
  }),
  computed: {
    ...mapGetters(["token"]),
    topLinks() {
      return this.role == "user" ? this.topUserLinks : this.topAdminLinks;
    },
  },
  watch: {
    $route: {
      handler() {
        this.checkRoute();
      },
    },
    profile: {
      handler() {
        if (this.role == "user") {
          const offersPageLink = this.topUserLinks.find(
            (i) => i.title === "Offers Page"
          );
          offersPageLink.visible = this.profile.plan?.isOfferPage;
        }
      },
    },
  },
  methods: {
    async cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;

      this.uploadLogo(imgDataUrl).then((res) => {
        this.show = false;
      });
    },
    goToPage(link) {
      this.topLinks.forEach((i) => {
        if (link.title == i.title) {
          i.active = true;
        } else {
          i.active = false;
        }
      });
      this.$router.push(link.route);
    },
    goToPageBottom(link) {
      this.topLinks.forEach((i) => (i.active = false));
      this.bottomLinks.forEach((i) => {
        if (link.route == i.route) {
          i.active = true;
        } else {
          i.active = false;
        }
      });
      this.$router.push(link.route);
    },
    checkRoute() {
      this.topLinks.forEach((i) => (i.active = false));
      this.bottomLinks.forEach((i) => (i.active = false));

      let activeRoute = this.topLinks.filter(
        (i) => i.route == this.$route.fullPath
      );
      if (activeRoute.length > 0) {
        this.topLinks.filter(
          (i) => i.route == this.$route.fullPath
        )[0].active = true;
        return;
      }
      this.bottomLinks.filter(
        (i) => i.route == this.$route.fullPath
      )[0].active = true;
    },
    logoutHandler() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
  },
  mounted() {
    this.checkRoute();
  },
};
</script>

<style lang="scss">
.side-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 260px;
  z-index: 99;
  border-right: 1px solid #acacac;

  &__head {
    height: 90px;
    border-radius: 25px;
  }

  &__profile {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    margin: auto;
    position: relative;
    top: -30px;
    margin-bottom: 30px;

    &__edit {
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
    }
    &:hover & {
      &__edit {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .bottom-links {
    border-top: 1px solid #acacac;
  }

  .active-icon {
    filter: invert(0%) sepia(6%) saturate(7476%) hue-rotate(287deg)
      brightness(97%) contrast(106%);
  }
  .side-tab {
    position: relative;

    // &::before {
    //   content: "";
    //   left: 0px;
    //   width: 5px;
    //   height: 100%;
    //   position: absolute;
    //   opacity: 0;
    //   transition: 0.3s;
    //   background: $lightgreen;
    //   border-radius: 0px 15px 15px 0px;
    // }

    &--active {
      border-radius: 0px 15px 15px 0px;
      background: #0195a9;
      color: #ffffff;

      // &::before {
      //   opacity: 1;
      // }
    }
  }
  .close-arrow {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    right: 0px;
  }
}
</style>

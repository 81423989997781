<template>
  <div class="mobile-layout">
    <div
      class="mobile-navbar bg-grey d-flex align-center justify-space-between px-4 py-3"
    >
      <img
        class="cursor-pointer"
        :src="require('@/assets/images/icons/menu.svg')"
        alt=""
        @click="drawer = true"
      />
      <img
        class="cursor-pointer"
        width="70px"
        :src="require('@/assets/images/global/logo-blue.svg')"
        alt=""
      />
      <!-- <notification-mobile></notification-mobile> -->
      <div></div>
    </div>

    <v-navigation-drawer v-model="drawer" fixed temporary left >
      <drawer-mobile @close="drawer = false"></drawer-mobile>
    </v-navigation-drawer>
  </div>
</template>

<script>
import NotificationMobile from "./components/NotificationMobile.vue";
import DrawerMobile from "./components/Drawer.vue";
export default {
  components: { DrawerMobile },
  data: () => ({
    drawer: false,
  }),
  watch: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.mobile-layout {
  .mobile-navbar {
    width: 100%;
  }
}
</style>

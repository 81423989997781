<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        :class="
          !isMobile &&
          !hideLayout &&
          !$route.name.includes('Auth') &&
          toggleLayout
            ? 'content '
            : 'white'
        "
      >
        <div
          v-if="!$route.name.includes('Auth') && !hideLayout && toggleLayout"
        >
          <!-- desktop head -->
          <div v-if="!isMobile" class="navbar-max-width py-4">
            <div class="max-width d-flex align-center justify-space-between">
              <span class="black--text font-24"></span>
              <div class="d-flex align-center">
                <!-- <div
                  class="py-3 radius-10"
                  @click="$router.push('/settings')"
                  v-if="profile.currencyName"
                >
                  <span class="font-18 font-600"
                    >{{ profile.currencyName }} ({{
                      profile.currencySymbol
                    }})</span
                  >
                </div> -->

                <div class="d-flex align-center py-3 radius-10">
                  <div
                    v-if="accountStatus === 'trialing' && profile.trial"
                    class=""
                  >
                    <span
                      v-if="!isTrialWillExpire"
                      class="trial-countdown d-inline-block"
                      ><span class="font-600">Free Trial</span> ({{
                        $moment(profile.trial.date)
                          .tz(this.profile.timeZone)
                          .fromNow(true) + " remaining"
                      }})
                    </span>

                    <v-btn
                      v-else
                      @click="redirectToPayment()"
                      class="confirm-payment d-inline-block"
                      color="danger"
                      :loading="processing"
                    >
                      Please confirm the payment to continue ({{
                        $moment(profile.trial.date)
                          .tz(this.profile.timeZone)
                          .fromNow(true) + " remaining"
                      }})
                    </v-btn>
                  </div>

                  <div
                    v-if="
                      profile.role !== 'admin' &&
                      accountStatus === 'active' &&
                      profile.plan &&
                      profile.plan.isFree &&
                      !profile.hadTrial
                    "
                    class="d-inline"
                  >
                    <v-btn class="trial-countdown" @click="redirectToUpgrade()"
                      >Try Ofero Pro:
                      <span class="font-600"> 14-Day Free Trial!</span></v-btn
                    >
                  </div>

                  <div
                    v-else-if="
                      profile.role !== 'admin' &&
                      accountStatus === 'active' &&
                      profile.plan &&
                      profile.plan.isFree &&
                      profile.hadTrial
                    "
                    class="d-inline"
                  >
                    <v-btn class="trial-countdown font-600" @click="redirectToUpgrade()"
                      >Upgrade to Pro</v-btn
                    >
                  </div>

                  <!-- currency -->
                  <span
                    v-if="profile.currency"
                    @click="showCurrencyDialog = true"
                    class="font-18 font-600 user-plan mx-3 cursor-pointer"
                  >
                    {{ profile.currency.symbol }}
                  </span>

                  <!-- plan -->
                  <span
                    v-if="profile.plan"
                    class="font-18 font-600 user-plan"
                    >{{ profile.plan.title }}</span
                  >
                </div>
                <!-- <notification-menu></notification-menu> -->
                <!-- <user-menu v-if="token" :userInfo="profile"></user-menu> -->
              </div>
            </div>
          </div>

          <mobile-layout v-if="isMobile"></mobile-layout>
          <side-menu
            v-if="!isMobile && !$route.name.includes('Auth')"
          ></side-menu>
        </div>
        <router-view />

        <!-- currency dialog -->
        <v-dialog
          v-model="showCurrencyDialog"
          max-width="400"
          content-class=" radius-25"
        >
          <currency-dialog
            :item="profile"
            @close="showCurrencyDialog = false"
          ></currency-dialog>
        </v-dialog>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import NotificationMenu from "./components/NotificationMenu.vue";
import UserMenu from "./components/UserMenu.vue";
import MobileLayout from "./MobileLayout.vue";
import SideMenu from "./SideMenu.vue";
import CurrencyDialog from "../components/dialogs/CurrencyDialog.vue";

export default {
  components: { SideMenu, MobileLayout, CurrencyDialog },
  computed: {
    ...mapGetters(["token", "toggleLayout"]),
    hideLayout() {
      if (this.$route.name == "Plans") {
        return true;
      } else {
        return false;
      }
    },
    isAdmin() {
      return localStorage.getItem("role") == "admin";
    },
    isTrialWillExpire() {
      const threeDaysBeforeExpire = this.$moment(this.profile.trial.date)
        .tz(this.profile.timeZone)
        .subtract(3, "days")
        .format();
      const today = this.$moment().tz(this.profile.timeZone).format();

      // add "+" to convert dates to milliseconds
      return +new Date(threeDaysBeforeExpire) <= +new Date(today);
    },
    isUpgradePage() {
      return this.$router.currentRoute.name === "My Plan";
    },
  },
  data() {
    return {
      showCurrencyDialog: false,
      processing: false,
    };
  },
  mounted() {
    // console.log(
    //   this.$moment(this.profile.trial.date).fromNow(true) + " remaining",
    //   this.$moment(this.profile.trial.date).subtract(3, "days").format() >
    //     this.$moment().format()
    // );
    console.log(this.$router.currentRoute.name);
  },
  methods: {
    redirectToPayment() {
      this.processing = true;

      this.$axios
        .post(process.env.VUE_APP_SUBSCRIPTIONS, {
          plan: this.profile.plan._id,
          stripeId: this.profile.trial.planStripeId,
        })
        .then((res) => {
          window.open(res.data.url);
          this.processing = false;
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("showSnack", {
            text: "Something went wrong!",
            color: "error",
          });
          this.processing = false;
        });
    },
    redirectToUpgrade() {
      if (this.$router.currentRoute.name === "My Plan") {
        return;
      }

      this.$router.push("/my-plan");
    },
  },
};
</script>

<style lang="scss">
.navbar-max-width {
  padding: 0px;
}

.trial-countdown {
  height: 47px !important;
  background-color: $primary !important;
  color: white !important;
  padding: 11px;
  border-radius: 10px;
}

.v-btn.confirm-payment {
  height: 47px !important;
  background-color: $danger;
  color: white;
  padding: 11px !important;
  border-radius: 10px;
}
</style>

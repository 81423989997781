var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu bg-grey py-8 d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-column overflow-y-auto"},[_c('div',{staticClass:"flex-grow-1 menu-links"},[_c('div',{staticClass:"side-menu__head text-center primary mx-7",class:[
          _vm.profile.logo || _vm.imgDataUrl ? 'align-start pt-3' : 'align-center mb-7',
        ]},[_c('img',{class:[_vm.profile.logo || _vm.imgDataUrl ? 'mt-2' : ''],attrs:{"src":require('@/assets/images/global/logo.svg'),"alt":""}})]),(_vm.profile.logo || _vm.imgDataUrl)?_c('div',{staticClass:"side-menu__profile"},[_c('div',{staticClass:"profile-img",class:[!_vm.profile.logo && !_vm.imgDataUrl ? 'profile-img__empty' : '']},[(_vm.profile.logo || _vm.imgDataUrl)?_c('img',{attrs:{"width":"100%","height":"100%","src":_vm.profile.logo ? _vm.profile.logo.url : _vm.imgDataUrl}}):_vm._e()]),_c('div',{staticClass:"side-menu__profile__edit primary",on:{"click":function($event){_vm.show = true}}},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require('@/assets/images/icons/pencil.svg'),"alt":""}})])]):_vm._e(),_vm._l((_vm.topLinks),function(link,i){return _c('div',{key:'top' + i,staticClass:"cursor-pointer side-tab pl-10 mr-8",class:link.active ? 'side-tab--active' : '',attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.goToPage(link)},"focus":_vm.toggleProTip,"blur":_vm.toggleProTip}},[_c('div',{staticClass:"mb-2 py-1 position-relative"},[_c('span',{staticClass:"font-22 d-flex align-center"},[_vm._v(_vm._s(link.title)+" "),_c('span',[(
                  i == 2 &&
                  link.title === 'Offers Page' &&
                  _vm.profile.plan &&
                  _vm.profile.plan.isFree
                )?_c('img',{staticClass:"pro-flag ml-2",attrs:{"width":"20px","height":"20px","src":require('@/assets/images/icons/pro-flag.svg')}}):_vm._e()])])])])}),_c('div',{staticClass:"bottom-links mx-10 my-3"}),_vm._l((_vm.bottomLinks),function(link,i){return _c('div',{key:'bottom' + i,staticClass:"side-tab px-10 mr-8",class:link.active ? 'side-tab--active' : '',on:{"click":function($event){return _vm.goToPageBottom(link)}}},[_c('div',{staticClass:"mt-2 py-1 cursor-pointer"},[_c('span',{staticClass:"font-22"},[_vm._v(_vm._s(link.title))])])])}),(_vm.token)?_c('div',{staticClass:"mt-1 cursor-pointer px-11",on:{"click":_vm.logoutHandler}},[_c('span',{staticClass:"lightred--text font-22"},[_vm._v("Logout")])]):_vm._e()],2),(_vm.profile.planDetails)?_c('div',{staticClass:"plan-details ms-10 mt-10"},[_c('span',{staticClass:"d-block font-16 font-600"},[_vm._v("Plan Details")]),_c('div',{staticClass:"plan-details__cover"},[_c('span',{staticClass:"d-block font-500 font-16"},[_vm._v("Items Count: "),_c('span',{staticClass:"font-600"},[_vm._v(_vm._s(_vm.profile.planDetails ? _vm.profile.planDetails.productsAllowed : ""))])]),_c('span',{staticClass:"d-block font-500 font-16"},[_vm._v(" Items Used: "),_c('span',{staticClass:"font-600"},[_vm._v(_vm._s(_vm.profile.planDetails ? _vm.profile.planDetails.productsUsed : "0"))])])]),(
          !(_vm.profile.plan && _vm.profile.plan.isFree) &&
          _vm.profile.planDetails.endDate
        )?_c('span',{staticClass:"d-block font-12 mt-1"},[_vm._v("Expire Date "),_c('span',{staticClass:"font-600"},[_vm._v(" "+_vm._s(_vm.$moment(_vm.profile.planDetails.endDate) .tz(this.profile.timeZone) .format("MMM DD, YYYY")))])]):_c('span',{staticClass:"d-block font-18 mt-1"},[_vm._v(" Free lifetime ")])]):_vm._e()]),_c('v-dialog',{attrs:{"content-class":"no-shadow white-bg radius-15 relative upload-dialog"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('my-upload',{attrs:{"field":"img","width":300,"height":300,"img-format":"png","langType":"en","noSquare":true},on:{"crop-success":_vm.cropSuccess}}),_c('img',{staticClass:"upload-cancel cursor-pointer",attrs:{"width":"15px","height":"15px","src":require('@/assets/images/icons/close.svg')},on:{"click":function($event){_vm.show = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="d-flex align-center">
    <img
      class="cursor-pointer "
      width="29px"
      height="29px"
      :src="require('@/assets/images/icons/notification.svg')"
      @click="dialog = true"
    />
    <v-dialog v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
      content-class="white notifications-mobile"
    >
      <v-list class="darkgray" elevation="0">
        <v-list-item
          class="logged-nav__item px-4 d-flex align-center py-1 cursor-pointer"
          v-for="(item, index) in [1, 1, 11, 1]"
          :key="index"
        >
          <div class="logged-nav__notification-circle mr-3">
            <img
              class="object-cover"
              width="100%"
              height="100%"
              alt=""
            />
          </div>
          <span class="font-16 icon-grey--text">title</span>
        </v-list-item>
      </v-list>

      <div
        class="close-notification text-center lightprimary cursor-pointer py-2"
        @click="dialog = close"
      >
        <span class="black--text">Close</span>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
};
</script>

<style lang="scss">
.profile-popup {
  position: relative;
}
.notifications-mobile{
    overflow: hidden;
    top: 50px !important;
    height: calc(100vh - 150px ) !important;
    .close-notification{
        position: absolute;
        bottom: 0px;
        left: 0px
    }
}
</style>
